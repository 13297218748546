'use client';

import Image from 'next/image';

import RA1000MiniIcon from '@/assets/illustrations/ra1000/selo-RA1000-mini.svg';
import { ImageResponsiveCMSProps } from '@/types/cms';

import Button, { ButtonProps } from '../../base/ui/Button';
import Icon, { IconsNames } from '@/components/v3/Icon';
import { useContext, useEffect } from 'react';
import { AmplitudeContext } from '@/contexts/amplitude';
import { useInView } from 'react-intersection-observer';

interface Props {
  referId: string;
  tag: string;
  title: string;
  description: string;
  buttons: ButtonProps[];
  image: ImageResponsiveCMSProps;
  indicators: {
    title: string;
    value: string;
    legend: string;
  }[];
  legend: string;
  raAssessment?: {
    title: string;
    imageSrc: string;
    imageAlt: string;
    iconLegend: IconsNames;
  };
}

export function IndicatorsCard({
  referId,
  tag,
  title,
  description,
  image,
  indicators,
  buttons,
  legend,
  raAssessment,
}: Props) {
  const [ref, inView] = useInView({ triggerOnce: true });
  const { analytics } = useContext(AmplitudeContext);

  useEffect(() => {
    if (inView) {
      analytics?.track({
        event_type: 'section viewed',
        event_properties: {
          name: 'section viewed',
          description:
            'Evento disparado quando o usuário visualiza uma sessão especifica da pagina',
          section_reference: title,
        },
      });
    }
  }, [analytics, inView]);

  return (
    <section id={referId} ref={ref}>
      <div className="relative w-full h-[213px] md:h-[395px] lg:h-[466px]">
        <div className="hidden lg:flex">
          <Image
            src={image.web.path}
            alt={image.web.alternativeText}
            quality={image.webQuality || 70}
            fill
            style={{ objectFit: 'cover' }}
          />
        </div>

        <div className="hidden md:flex lg:hidden">
          <Image
            src={image.tablet.path}
            alt={image.tablet.alternativeText}
            quality={image.mobileQuality || 70}
            fill
            style={{ objectFit: 'cover' }}
          />
        </div>

        <div className="flex md:hidden">
          <Image
            src={image.mobile.path}
            alt={image.mobile.alternativeText}
            quality={image.mobileQuality || 70}
            fill
            style={{ objectFit: 'cover' }}
          />
        </div>
      </div>

      <div className="mx-24 mb-40 md:mb-0">
        <div className="grid relative md:grid-cols-1 lg:grid-cols-2 sm:p-24 md:p-40 m-auto mt-[24px] md:mt-[-134px] lg:mt-[-163px] lg:mb-[60px] max-w-[1060px] bg-display-0 md:rounded-md md:border-2 md:border-display-200">
          <div className="flex flex-col justify-center lg:max-w-[387px]">
            <p
              className="text-sm md:text-base font-semibold text-center lg:text-left text-display-600"
              dangerouslySetInnerHTML={{ __html: tag }}
            />

            <h2
              className="pt-8 font-display text-2xl md:text-3xl font-bold text-center lg:text-left text-display-900"
              dangerouslySetInnerHTML={{ __html: title }}
            />

            <p
              className="pt-8 font-normal text-base md:text-lg text-center lg:text-left text-display-900"
              dangerouslySetInnerHTML={{ __html: description }}
            />

            <div className="hidden lg:flex gap-16 pt-24">
              {buttons?.map(button => (
                <Button
                  {...button}
                  key={button.label}
                  sectionReference={title}
                />
              ))}
            </div>
          </div>

          <div className="flex flex-col items-center md:items-end mt-24 lg:mt-0">
            <div className="flex flex-col items-center w-full">
              <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 gap-8 md:gap-16 w-full">
                {raAssessment ? (
                  <div className="lg:p-8 py-16 px-8 space-y-4 md:space-y-12 min-w-[152px] text-center bg-display-100 rounded-[8px] md:rounded-md">
                    <p className="font-normal text-sm md:text-base">
                      {raAssessment.title}
                    </p>
                    <Image
                      src={raAssessment.imageSrc}
                      width={66}
                      height={40}
                      alt={raAssessment.imageAlt}
                    />
                  </div>
                ) : (
                  <div className="lg:p-8 py-16 px-8 min-w-[152px] text-center bg-display-100 rounded-[8px] md:rounded-md">
                    <p className="font-normal text-sm md:text-base">
                      Reconhecido
                    </p>
                    <div className="flex justify-center items-center">
                      <Image
                        src="https://strapi-cms-media-customer-platforms-prd-264a.s3.us-east-1.amazonaws.com/stone-cms-prd/Selo_RA_1000_pneubest_3_Photoroom_ed138bbbdf.png"
                        alt="Selo Reclame aqui"
                        width={39}
                        height={62}
                      />
                      <div className="flex flex-col justify-start items-start ml-[3px] font-display">
                        <Image
                          src="https://strapi-cms-media-customer-platforms-prd-264a.s3.us-east-1.amazonaws.com/stone-cms-prd/Selo_RA_1000_pneubest_2_6add477060.png"
                          alt="Reclame aqui 1000"
                          width={91}
                          height={41}
                        />
                        <p className="pt-4 font-display ">
                          <span className="text-4xl font-bold">9.0</span>
                          <span className="text-xl">/10</span>
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {indicators.map(({ title, value, legend }) => (
                  <div
                    key={title}
                    className="lg:p-8 py-16 px-8 space-y-4 md:space-y-12 min-w-[152px] text-center bg-display-100 rounded-[8px] md:rounded-md"
                  >
                    <p className="font-normal text-sm md:text-base">{title}</p>
                    <p className="font-display text-4xl md:text-6xl font-bold">
                      {value}
                    </p>
                    <p className="font-normal text-sm md:text-base">{legend}</p>
                  </div>
                ))}
              </div>

              <div className="flex pt-16">
                {raAssessment ? (
                  <Icon
                    name={raAssessment.iconLegend as IconsNames}
                    className="w-24 h-24 text-stone-500 fill-current"
                  />
                ) : (
                  <RA1000MiniIcon />
                )}
                <p
                  className="pl-[4px] font-display text-base md:text-xl font-medium"
                  style={{ color: '#018D45' }}
                >
                  {' '}
                  {legend}
                </p>
              </div>

              <div className="flex lg:hidden justify-center mt-24 lg:mt-0 w-full">
                {buttons?.map(button => (
                  <Button
                    {...button}
                    key={button.label}
                    sectionReference={title}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
